import React from "react";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { PRIORITY, STATUS, TYPE } from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import { createRequirementPendingItems } from "../../../store/actions/Requirement";
import { createPendingItems, getAllUsers } from "../../../store/actions/Task";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { formatDate } from "../../../utility/idelTimer";
import { ProfileType } from "../../../utility/commonTypes";

interface PendingItems {
  id: any;
  requirement_id: any;
  type: any;
  date: any;
  due_date: any;
  issue: any;
  priority: any;
  status: any;
  resolve_comments: any;
  assigned_to: any;
  internal: any;
  created_by: any;
  allow_notifications: any;
  actionType: any;
}
const RequirementPendingItemForm = ({
  row,
  onPendingItemSuccess,
  openModalPop,
  handleClose,
  setOpenModalPop,
}: any) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const [assignedToUserId, setAssignedToUserId] = useState<string>("");
  const [internalCheck, setInternalCheck] = useState(false);
  const [allowNotificationCheck, setAllowNotification] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("create");
  const [suggestions, setSuggestions] = useState([]);
  const params: any = useParams();
  const stateData: any = params;

  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });
  const current = new Date();

  let defaultDate = "";
  if (current.getDate() >= 1 && current.getDate() <= 9 && current.getMonth()+1>=1 && current.getMonth()+1<=9) {
    defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
      "0" + current.getDate()
    }`;
  }
    else if(current.getDate() >= 1 && current.getDate() <= 9){
      defaultDate = `${current.getFullYear()}-${current.getMonth()+1}-${
        "0" + current.getDate()
      }`;
    }
    else if(current.getMonth()+1 >= 1 && current.getMonth()+1 <= 9){
      defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
        current.getDate()
      }`;
    

  } else {
    defaultDate = `${current.getFullYear()}-${
      current.getMonth() + 1
    }-${current.getDate()}`;
  }
  const location = useLocation();
  const indexId = useRef();
  const handleInternalCheckBox = (e: any) => {
    setInternalCheck(e.target.checked);
  };

  const handleAllowNotificationCheckBox = (e: any) => {
    setAllowNotification(e.target.checked);
  };

  const createButton = {
    textAlign: "right",
  };
  const [open, setOpen] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    setOpen(false);
  };
  
  const {
    handleSubmit,
    handleChange,
    data: pendingItems,
    errors,
    setData,
  } = useForm<PendingItems>({
    validations: {
      issue:{
        required: {
          value: true,
          message: "Required field",
        },
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      },
      type: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      priority: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      status: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      due_date: {
        required: {
          value: true,
          message: "Required field",
        },
        custom: {
          isValid: (value) => {
            if (actionType === "create") {
              const selected = new Date(value);
              const maxDate = new Date();
              maxDate.setHours(0, 0, 0, 0);
              maxDate.setDate(maxDate.getDate() + 1);
              console.log(maxDate);
              if (selected < maxDate) {
                return false;
              }
              return true;
            }
            return true;
          },
          message: "Due date should not be less than Date",
        },
      },
      assigned_to: {
        custom: {
          isValid: (value) => {
            console.log("Assigned to Validation", value);
            if (
              value !== undefined &&
              value != null &&
              value !== "" &&
              assignedToUserId === ""
            ) {
              console.log("Invalid Assigned To");
              return false;
            }
            return true;
          },
          message: "Please select valid Assigned To",
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      {
        pendingItems?.id === ""
          ? dispatch(
              createPendingItems(
                {
                  payload: {
                    id: pendingItems?.id,
                    requirement_id: stateData?.requirementId,
                    task_id: stateData?.taskId,
                    type: pendingItems?.type,
                    date: defaultDate,
                    due_date: pendingItems?.due_date,
                    issue: pendingItems?.issue,
                    created_by: pendingItems?.created_by,
                    priority: pendingItems?.priority,
                    status: pendingItems?.status,
                    resolve_comments: pendingItems?.resolve_comments,
                    assigned_to: pendingItems?.user_id,
                    internal: internalCheck,
                    allow_notifications: allowNotificationCheck,
                    actionType: actionType,
                  },
                },

                onCreatePendingItemCallback
              )
            )
          : dispatch(
              createPendingItems(
                {
                  payload: {
                    id: pendingItems?.id,
                    requirement_id: stateData?.requirementId,
                    task_id: stateData?.taskId,
                    type: pendingItems?.type,
                    date: pendingItems?.date,
                    due_date: pendingItems?.due_date,
                    issue: pendingItems?.issue,
                    created_by: pendingItems?.created_by,
                    priority: pendingItems?.priority,
                    status: pendingItems?.status,
                    resolve_comments: pendingItems?.resolve_comments,
                    assigned_to: pendingItems?.user_id,
                    internal: internalCheck,
                    allow_notifications: allowNotificationCheck,
                    actionType: actionType,
                  },
                },

                onCreatePendingItemCallback
              )
            );
      }
    },
  });
  //console.log("pending Items:",pendingItems);
  const [errorPopup, setErrorPopup] = useState(false);
  const onCreatePendingItemCallback = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      //on Success redirect to parent page and reload the data grid
      // onPendingItemSuccess();
      onPendingItemSuccess();
    }
    setOpenModalPop(false);
    setLoader(false);
  };
  useEffect(() => {
    if (row && row?.id) {
      console.log("details", row);
      row?.id && setActionType("update");
      setAssignedToUserId(row?.user_id);
      setData({
        requirement_id: row?.requirement_id,
        id: row?.id,
        task_id: row?.task_id,
        type: row?.type,
        date: row?.date,
        due_date: row?.due_date,
        created_by: row?.created_by,
        priority: row?.priority,
        status: row?.status,
        resolve_comments: row?.resolve_comments,
        issue: row?.issue,
        assigned_to: row?.assigned_to,
        internal: row?.internal,
        allow_notifications: row?.allow_notifications,
      });
      setInternalCheck(row?.internal);
      setAllowNotification(row?.allow_notifications);
    } else {
      setActionType("create");
      setData({
        requirement_id: "",
        task_id: "",
        id: "",
        type: "",
        date: "",
        due_date: "",
        issue: "",
        created_by: "",
        priority: "",
        status: "",
        resolve_comments: "",
        assigned_to: "",
        internal: false,
        allow_notifications: false,
      });
    }
  }, [setData, row]);
  const handleKeyUpEvent = (event: any) => {
    dispatch(
      getAllUsers(
        { searchWord: event.target.value, id: stateData?.taskId },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }

          setSuggestions({
            ...res,
          });
        }
      )
    );
  };

  useEffect(() => {
    openModalPop && setOpenModalPop(true);
  }, [openModalPop]);
  let suggestRef=useRef()
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  // console.log("default date:",defaultDate);
  return (
    <>
      <CustomModal
        title="Add Requirement Pending Items"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Type*</label>
                    <select
                      onChange={handleChange("type")}
                      name="type"
                      id="type"
                      value={pendingItems?.type}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {TYPE.map((type) => (
                        <option value={type.key}>{type.label}</option>
                      ))}
                    </select>
                    {errors.due_date && (
                      <p className="error-text">{errors.due_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date</label>

                    {pendingItems?.id === "" ? (
                      <input
                        type="date"
                        defaultValue={defaultDate}
                        placeholder="DD-MMM-YYYY"
                        //data-date={defaultDate ?formatDate(defaultDate):"DD-MMM-YYYY"}
                        className="form-control"
                        disabled
                      />
                    ) : (
                      <input
                        type="date"
                        value={pendingItems?.date}
                        placeholder="DD-MMM-YYYY"
                        //data-date={pendingItems?.date ?formatDate(pendingItems?.date):"DD-MMM-YYYY"}
                        className="form-control"
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Due Date*</label>
                    <input
                      type="date"
                      value={pendingItems?.due_date}
                      onChange={handleChange("due_date")}
                      className="form-control"
                      placeholder="DD-MMM-YYYY"
                      //data-date={pendingItems?.due_date ?formatDate(pendingItems?.due_date):"DD-MMM-YYYY"}
                    />
                    {errors.due_date && (
                      <p className="error-text">{errors.due_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Issue*</label>
                    <textarea
                      name="issue"
                      id="issue"
                      onChange={handleChange("issue")}
                      value={pendingItems?.issue}
                      className="form-textarea"
                      
                    ></textarea>
                    {errors.issue && (
                      <p className="error-text pt-2 ">{errors.issue}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Priority*</label>
                    <select
                      name="priority"
                      id="priority"
                      value={pendingItems?.priority}
                      onChange={handleChange("priority")}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {PRIORITY.map((priority) => (
                        <option value={priority.key}>
                          {priority.label}
                        </option>
                      ))}
                    </select>
                    {errors.due_date && (
                      <p className="error-text">{errors.due_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Status*</label>
                    <select
                      name="status"
                      id="status"
                      value={pendingItems?.status}
                      className="form-control"
                      onChange={handleChange("status")}
                    >
                      <option value="">--Select--</option>
                      {STATUS.map((status) => (
                        <option value={status.key}>{status.label}</option>
                      ))}
                    </select>
                    {errors.due_date && (
                      <p className="error-text">{errors.due_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Assigned To</label>
                    <input
                      type="text"
                      onKeyUp={handleKeyUpEvent}
                      onChange={handleChange("assigned_to", (val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      name="assigned_to"
                      id="asigned_to"
                      value={pendingItems?.assigned_to}
                      className="form-control"
                    />
                    {errors.assigned_to && (
                      <p className="error-text">{errors.assigned_to}</p>
                    )}
                    {showSuggestion && (
                      <ul className="list-group" ref={suggestRef}>
                        {" "}
                        {Object.entries(suggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setShowSuggestion(false);
                                  setAssignedToUserId(i?.User?.id);
                                  setData({
                                    ...pendingItems,
                                    assigned_to:
                                      i?.User?.CustomerContactName ||
                                      i?.User?.ResourceName,
                                    user_id: i?.User?.id,
                                  });
                                }}
                              >
                                {i?.User?.CustomerContactName ||
                                  i?.User?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                {pendingItems?.status === "Completed" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Comments</label>
                      <textarea
                        name="resolve_comments"
                        rows={3}
                        cols={6}
                        // className="form-control"
                        value={pendingItems?.resolve_comments}
                        onChange={handleChange("resolve_comments")}
                        className="form-textarea"
                      ></textarea>
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={handleInternalCheckBox}
                      checked={internalCheck}
                      value={pendingItems?.internal}
                      className="form-check-input mx-2"
                    />
                    <label
                      htmlFor="checked"
                      className="form-check-label mt-1"
                      style={{ width: "80px" }}
                    >
                      Internal
                    </label>
                  </div>

                  <div className="d-flex">
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={handleAllowNotificationCheckBox}
                      value={pendingItems?.allow_notifications}
                      checked={allowNotificationCheck}
                      className="form-check-input mx-2"
                    />
                    <label
                      htmlFor="checked"
                      className="form-check-label mt-1"
                      style={{ width: "150px" }}
                    >
                      Allow Notifications
                    </label>
                  </div>
                </div>
                {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable) || ProfileType?.ADMIN === getProfileType ? 
         <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                      onClick={() => {
                        setLoader(false);
                      }}
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>:null}
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title=" Requirement Pending Items"
        onOpen={errorPopup}
        content={"Error!Please try again"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title=" Requirement Pending Items"
        onOpen={successPopup}
        content={"Requirement Pending Items Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onPendingItemSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
            <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};
export default RequirementPendingItemForm;
