import React, { useState, useRef, useEffect, FC, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { errorMessages, PRIORITY, REPLACE_REGEX, STATUS, TYPE } from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import {
  createPendingItems,
  getAllUsers,
  getTaskPendingItems,
} from "../../../store/actions/Task";
import "./pendingItemForm.css";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import moment from "moment";
import { formatDate } from "../../../utility/idelTimer";
import { ProfileType } from "../../../utility/commonTypes";

interface PendingItems {
  id: any;
  user_id: any;
  task_id: any;
  type: any;
  date: any;
  due_date: any;
  issue: any;
  priority: any;
  status: any;
  resolve_comments: any;
  assigned_to: any;
  internal: any;
  created_by: any;
  allow_notifications: any;
  actionType: any;
}
const PendingItemForm: FC = ({
  row,
  openModalPop,
  setOpenModalPop,
  handleClose,
  onSuccess,
}: any) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const [assignedToUserId, setAssignedToUserId] = useState<string>("");
  const [internalCheck, setInternalCheck] = useState(false);
  const [allowNotificationCheck, setAllowNotification] = useState(false);

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [actionType, setActionType] = useState("create");
  const [check, setChecked] = useState(false);
  const [rowIdSelected, setRowId] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const location = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const[message,setMessage]=useState("")
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  const isEditable = useSelector((store: any) => {
    return store?.taskDetails?.taskEditable?.[stateData?.taskId];
  });

  const currentUser = useSelector((store: any) => {
    return store.auth.globalAuthData.emailid;
  });
  const [taskDetail, setTaskDetail] = useState({
    id: "",
  });
  const [showSuggestion, setShowSuggestion] = useState(false);

  const current = new Date();
  console.log("get month",current.getMonth()+1,current.getDate())
  let defaultDate = "";
  if (current.getDate() >= 1 && current.getDate() <= 9 && current.getMonth()+1>=1 && current.getMonth()+1<=9) {
    defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
      "0" + current.getDate()
    }`;
  }
    else if(current.getDate() >= 1 && current.getDate() <= 9){
      defaultDate = `${current.getFullYear()}-${current.getMonth()+1}-${
        "0" + current.getDate()
      }`;
    }
    else if(current.getMonth()+1 >= 1 && current.getMonth()+1 <= 9){
      defaultDate = `${current.getFullYear()}-${"0"+(current.getMonth()+1)}-${
        current.getDate()
      }`;
    }
  else {
    defaultDate = `${current.getFullYear()}-${
    current.getMonth() + 1
    }-${current.getDate()}`;
  }
  console.log("date is",defaultDate)

  const handleInternalCheckBox = (e: any) => {
    setInternalCheck(e.target.checked);
  };

  const handleAllowNotificationCheckBox = (e: any) => {
    setAllowNotification(e.target.checked);
  };
  const [open, setOpen] = useState(false);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    handleSubmit,
    handleChange,
    data: pendingItems,
    errors,
    setData,
  } = useForm<PendingItems>({
    validations: {
      due_date: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        custom: {
          isValid: (value) => {
            if (actionType === "create") {
              const selected = new Date(value);
              const maxDate = new Date();
              maxDate.setHours(0, 0, 0, 0);
              maxDate.setDate(maxDate.getDate() + 1);
              console.log(maxDate);
              if (selected < maxDate) {
                return false;
              }
              return true;
            }
            return true;
          },
          message: "Due date should not be less than Date",
        },
      },
      type: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      priority: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      status: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      assigned_to: {
        custom: {
          isValid: (value) => {
            console.log("Assigned to Validation", value);
            if (
              value !== undefined &&
              value != null &&
              value !== "" &&
              assignedToUserId === ""
            ) {
              console.log("Invalid Assigned To");
              return false;
            }
            return true;
          },
          message: "Please select valid Assigned To",
        },
      },
      issue:{
        required: {
          value: true,
          message: "Required field",
        },
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Issue",5000)
        },
      },
      resolve_comments:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Comments",5000)
        },
      }
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      {
        pendingItems?.id === ""
          ? dispatch(
              createPendingItems(
                {
                  payload: {
                    task_id: stateData?.taskId,
                    id: pendingItems?.id,
                    type: pendingItems?.type,
                    date: defaultDate,
                    due_date: pendingItems?.due_date,
                    issue: pendingItems?.issue,
                    created_by: currentUser?.created_by,
                    priority: pendingItems?.priority,
                    status: pendingItems?.status,
                    resolve_comments: pendingItems?.resolve_comments,
                    assigned_to: pendingItems?.user_id,
                    internal: internalCheck,
                    allow_notifications: allowNotificationCheck,

                    actionType: actionType,
                  },
                },

                onCreatePendingItemCallback
              )
            )
          : dispatch(
              createPendingItems(
                {
                  payload: {
                    task_id: stateData?.taskId,
                    id: pendingItems?.id,
                    type: pendingItems?.type,
                    date: pendingItems?.date,
                    due_date: pendingItems?.due_date,
                    issue: pendingItems?.issue,
                    created_by: currentUser?.created_by,
                    priority: pendingItems?.priority,
                    status: pendingItems?.status,
                    resolve_comments: pendingItems?.resolve_comments,
                    assigned_to: pendingItems?.user_id,
                    internal: internalCheck,
                    allow_notifications: allowNotificationCheck,

                    actionType: actionType,
                  },
                },
                onCreatePendingItemCallback
              )
            );
      }
    },
  });
  const [errorPopup, setErrorPopup] = useState(false);
  const onCreatePendingItemCallback = (data: any,error:any) => {
    //    close the popup and reload the datagrid
    if (data == false) {
      setMessage(error)
      setErrorPopup(true);
    } else {
      //on Success redirect to parent page and reload the data grid
      onSuccess();
    }
    setOpenModalPop(false);
    setLoader(false);
  };
  console.log("row is", row);
  useEffect(() => {
    if (row && row?.id) {
      row?.id && setActionType("update");
      setAssignedToUserId(row?.user_id);

      setData({
        task_id: row?.task_id,
        id: row?.id,
        type: row?.type,
        date: row?.date,
        due_date: row?.due_date,
        issue: row?.issue,
        created_by: row?.created_by,
        priority: row?.priority,
        status: row?.status,
        resolve_comments: row?.resolve_comments,
        assigned_to: row?.assigned_to,
        internal: row?.internal,
        allow_notifications: row?.allow_notifications,
      });
      setAllowNotification(row?.allow_notifications);
      setInternalCheck(row?.internal);
    } else {
      setActionType("create");
      setData({
        task_id: "",
        id: "",
        type: "",
        date: "",
        due_date: "",
        issue: "",
        created_by: "",
        priority: "",
        status: "",
        resolve_comments: "",
        assigned_to: "",
        internal: false,
        allow_notifications: false,
      });
    }
  }, [setData, row]);

  const handleKeyUpEvent = (event: any) => {
    dispatch(
      getAllUsers(
        { searchWord: event.target.value, id: stateData?.taskId },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }
          setSuggestions({
            ...res,
          });
        }
      )
    );
  };

  useEffect(() => {
    openModalPop && setOpenModalPop(true);
  }, [openModalPop]);
  let suggestRef=useRef()
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  return (
    <>
      <CustomModal
        title="Add Task Pending Items"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Type*</label>
                    <select
                      onChange={handleChange("type")}
                      name="type"
                      id="type"
                      value={pendingItems?.type}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {TYPE.map((type) => (
                        <option value={type.key}>{type.label}</option>
                      ))}
                    </select>
                    {errors.type && (
                      <p className="error-text">{errors.type}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Date</label>
                    {pendingItems?.id === "" ? (
                      <input
                        type="date"
                        defaultValue={defaultDate}
                        className="form-control"
                        placeholder="DD-MMM-YYYY"
                        //data-date={defaultDate ? formatDate(defaultDate):"DD-MMM-YYYY"}
                        disabled
                      />
                    ) : (
                      <input
                        type="date"
                        value={pendingItems?.date}
                        placeholder="DD-MMM-YYYY"
                        //data-date={pendingItems?.date ?formatDate(pendingItems?.date):"DD-MMM-YYYY"}
                        className="form-control"
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Due Date*</label>
                    <input
                      type="date"
                      value={pendingItems?.due_date}
                      onChange={handleChange("due_date")}
                      placeholder="DD-MMM-YYYY"
                      //data-date={pendingItems?.due_date ? formatDate(pendingItems?.due_date):"DD-MMM-YYYY"}
                    className="form-control"
                    />
                    {errors.due_date && (
                      <p className="error-text">{errors.due_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Issue*</label>
                    <textarea
                      name="issue"
                      id="issue"
                      
                      onChange={handleChange("issue")}
                      value={pendingItems?.issue}
                      className="form-textarea"
                      //className="form-cotrol"
                    ></textarea>
                    {errors.issue && (
                      <p className="error-text pt-2 ">{errors.issue}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Priority*</label>
                    <select
                      name="priority"
                      id="priority"
                      value={pendingItems?.priority}
                      onChange={handleChange("priority")}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {PRIORITY.map((priority) => (
                        <option value={priority.key}>{priority.label}</option>
                      ))}
                    </select>
                    {errors.priority && (
                      <p className="error-text">{errors.priority}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Status*</label>
                    <select
                      name="status"
                      id="status"
                      value={pendingItems?.status}
                      onChange={handleChange("status")}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {STATUS.map((status) => (
                        <option value={status.key}>{status.label}</option>
                      ))}
                    </select>
                    {errors.status && (
                      <p className="error-text">{errors.status}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Assigned To</label>
                    <input
                      name="resources"
                      type="text"
                      value={pendingItems?.assigned_to}
                      onChange={handleChange("assigned_to", (val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpEvent}
                      placeholder="Resource Name"
                      className="form-control"
                    />
                    {errors.assigned_to && (
                      <p className="error-text">{errors.assigned_to}</p>
                    )}
                    {showSuggestion && (
                      <ul
                        className="list-group"
                        ref={suggestRef}
                        style={{ position: "absolute", zIndex: 1 }}
                      >
                        {""}
                        {Object.entries(suggestions)?.map(([, i]) => {
                          return (
                            <li
                              className="list-group-item"
                              onClick={() => {
                                setShowSuggestion(false);
                                setAssignedToUserId(i?.User?.id);
                                setData({
                                  ...pendingItems,
                                  assigned_to:
                                    i?.User?.CustomerContactName ||
                                    i?.User?.ResourceName,
                                  user_id: i?.User?.id,
                                });
                              }}
                            >
                              {i?.User?.CustomerContactName ||
                                i?.User?.ResourceName}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                {pendingItems?.status === "Completed" && (
                  <div className="col-md-6 marginTopCls">
                    <div className="form-group">
                      <label className="form-label">Comments</label>
                      <textarea
                        name="resolve_comments"
                        rows={3}
                        cols={6}
                        //className="form-control"
                        value={pendingItems?.resolve_comments}
                        onChange={handleChange("resolve_comments")}
                        className="form-textarea"
                      ></textarea>
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={handleInternalCheckBox}
                      checked={internalCheck}
                      value={pendingItems?.internal}
                      className="form-check-input mx-2"
                    />
                    <label
                      htmlFor="checked"
                      className="form-check-label mt-1"
                      style={{ width: "80px" }}
                    >
                      Internal
                    </label>
                  </div>

                  <div className="d-flex">
                    <input
                      type="checkbox"
                      name="checked"
                      onChange={handleAllowNotificationCheckBox}
                      checked={allowNotificationCheck}
                      value={pendingItems?.allow_notifications}
                      className="form-check-input mx-2"
                    />
                    <label
                      className="form-check-label mt-1"
                      style={{ width: "150px" }}
                    >
                      Allow Notifications
                    </label>
                  </div>
                </div>
                {((ProfileType?.USER === getProfileType || ProfileType?.CUSTOMER === getProfileType) && isEditable)|| ProfileType?.ADMIN === getProfileType  ? 
        <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>:null}
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title="Pending Items"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Pending Items"
        onOpen={successPopup}
        content={"Pending Items Created/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};
export default PendingItemForm;
