import { useForm } from "../helpers/validations/useForm";

export const common = {
  fileSize: 1000000,
};

export const APP_PREFIX = {
  GLOBAL: "apl",
};

export const IMAGE_COMPRESS_QUALITY = 0.6;

export const PAN = "PAN";
export const AADHAR = "Aadhar";
export const PASSPORT_NUMBER = "PassportNumber";

// business types
export const COMPANY = "Company";
export const SOLE_PROPRIETORSHIP = "Sole_Proprietorship";
export const INDIVIDUAL = "Individual";

//otp Types
export const SIGN_IN = "SIGNIN";
export const FORGOT_PASSWORD = "FORGOTPASSWORD";
export const REDEEM_PETROMILES = "REDEEM_PETROMILES";

export const USER_REGISTERED = "Completed";
export const USER_NOT_REGISTERED = "NOT_Registered";
export const USER_REGISTRATION_IN_PROGRESS = "In_Progress";
export const USER_REGISTRATION_APPROVAL_PENDING = "Pending";
export const USER_REGISTRATION_SUBMITTED = "Submitted";

export const APPROVED = "APPROVED";
export const NOTUPLOADED = "NOTUPLOADED";
export const REJECTED = "REJECTED";

export const CARD_ISSUE_STATUS = {
  NOT_DISPATCHED: "NOT DISPATCHED",
  DELIVERED: "DELIVERED",
  DISPATCHED: "DISPATCHED",
  NOT_APPLICABLE: "NOT APPLICABLE",
};

// Payment type
export const PAY_FEES = "PAY_FEES";
export const FEE_WAIVER = "FEE_WAIVER";
export const FEES_PAID = "FEES_PAID";
export const PAYMENT_NOT_APPLICABLE = "DUMMY";
export const PAYMENT_GATEWAY = "PAYMENT_GATEWAY";
// Password
export const PAYMENT_TYPES = [
  "PAY_BY_CMS_WALLET",
  "PAY_BY_PETROMILES",
  "PAYMENT_GATEWAY",
  "FEES_PAID",
  "FEE_WAIVER",
];
export const COMPANY_NAME = [
  "ABC CORP",
  "DEF INTERNATIONAL",
  "--INTERNAL--",
  "DELOITTE ENTITY LIST",
];
// order by
export const ASCENDING = "asc";
export const DESCENDING = "desc";
// sort by
export const SLNO = "slNo";
//card management bottom modal types
export const FILTER = "FILTER";

//TODO: Remove these status and change their respective imports to CARD_STATUS.
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const TERMINATED = "Terminated";
export const HOTLISTED = "Hotlisted";
export const UNASSIGNED = "Unassigned";
export const BLOCKED = "Blocked";

// card management Card status types
export const ALL_STATUS = "All";

export const USER_STATUS = {
  PENDING: "Pending",
  Rejected: "Rejected",
  Approved: "Approved",
};

//datetime formate
export const DATETIME = "ddd MMM DD HH:mm:ss z YYYY";
export const DATETIMEFORMAT = "DD/MM/YYYY, H:mm a";
export const DATE_CALENDER_FORMAT = "YYYYMMDD";

export const PDF = "pdf";
export const XL_SX = "xlsx";
export const CSV = "csv";

export const PRIMARY_DATE_FORMAT = "DD MMM YYYY HH:mm A";
export const SECONDARY_DATE_FORMAT = "DD MMM YY HH:mm A";
export const SF_CM_TRANSACTION_HISTORY_DATE_FORMAT = "DD MMM YY hh:mm A";
export const SF_CM_TRANSACTION_HISTORY_DETAIL_DATE_FORMAT =
  "DD MMMM YYYY, hh:mm A";
export const SF_CM_TRANSACTION_SUCCESS_DATE_FORMAT = "DD MMMM YYYY hh:mm A";
export const SD_PROFILE_DOB_FORMAT = "DD MMM YYYY";
export const TRANSACTION_DATE_FORMAT = "H:mm A, DD MMMM YYYY";

export const SD_DASHBOARD_DATE_FORMAT = "DD MMM, hh:mm a";

export const ISO_DATE_FORMATE = "YYYY-MM-DDTHH:mm:ss[Z]";

export const OTHERS = "OTHERS";

//Smartfleet Dashboard Date Range
export const THIS_MONTH = "This Month";

/**
 * Service Management Programs
 * @returns
 */

// Service request status
export const CLOSED = "Closed";

export const FORM_LENGTH = {
  NAME: 30,
  BUIDING: 50,
  STREET: 50,
  LANDMARK: 25,
  CITY: 50,
  PINCODE: 6,
  AADHAAR: 12,
  BANK_NAME: 29,
  MOBILE: 10,
  PAN: 10,
  FIRM_NAME_MIN: 3,
  FIRM_NAME_MAX: 60,
};

export const CONTACT_TELEPHONE = "1800 22 4344";
export const CONTACT_EMAIL = "support@apl.in";

export const DEFAULT = "DEFAULT";
export const CUSTOMER = "CUSTOMER";

export const PERSONA = {
  CUSTOMER: "CUSTOMER",
  DELOITTE_MEMBER: "DELOITTE_MEMBER",
};

export const REPLACE_REGEX = {
  ONLY_NUMBER: "^[0-9]*$",
  SPECIAL_CHAR_WITHOUT_SAPCE: /[^a-z0-9 /-]/gi,
  SPECIAL_CHAR_WITH_SAPCE: /[^a-z0-9]/gi,
  NO_NUMBER_GLOBAL: /[^0-9]/g,
  FILE_REMOVAL: "file://",
  SPECIAL_CHAR_WITHOUT_UNDERSCORE: /[^a-zA-Z0-9_]/g,
  NON_ALPHABETS: /[^a-z]/gi,
  NON_ALPHABETS_EXCLUDING_SPACE: /[^a-z ]/gi,
  LPG_PRODUCT: /[^a-z0-9 ]/gi,
  START_WITH_ALPHABETS_NUMBERS:"^[A-Za-z0-9](?:[a-zA-Z0-9\\s#_-\])*$",
  START_WITH_ONLY_ALPHABETS:"^[A-Za-z](?:[a-zA-Z0-9\\s-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])*$",
  COMPANY_NAME_OR_TASK_ID:"^[A-Za-z](?:[a-zA-Z0-9\\s-_.:#])*$",
  NUMBERS_WITH_DECIMALS:"^[0-9]*.[0-9]{0,2}$",
  START_WITH_ONLY_ALPHABETS_NO_SPECIAL_CHARS:"^[A-Za-z]( ?[A-Za-z0-9])*$",
  ALPHANUMERIC_WITH_SPECIAL_CHARS:"(^w|_d\n)*[A-Za-z0-9\n].*$",
  ALPHANUMERIC_WITHOUT_SPECIAL_CHARS:"^[A-Za-z0-9]( ?[A-Za-z0-9])*$",
  PHONE_NUMBER_FORMAT:"\\d{1,3}\\s\\d{10}$"

};
export const errorMessages= {
  required: () => 'Required Field',
  pattern: () => `Please enter combination of Alphanumeric and special characters allowed`,
  patternStartsWithAlphabets:()=>`Please enter combination of Alphanumeric.Starts with only alphabets`,
  patternWithOutSpecialChars:()=>`Please enter combination of Alphanumeric and special characters not allowed`,
  patternWithOnlyNumbers:()=>`Please enter only numbers`,
  patternWithOnlyDecimalNumbers:()=>`Please enter numbers upto 2 Decimals`,

  minlength: (name:any,param:any) => ` ${name} must have at least ${param} characters`,
  maxlength: (name:any,param:any) => `${name} should not exceed ${param} characters`,
  allowedRange:(name:any)=>`${name} range is {0-999999.99}`
};

export const ENVIRONMENT = {
  DEV: "development",
  STG: "qa",
  PROD: "prod",
};
export const MAP_URL_SCHEMES = {
  iosGoogleMap: "comgooglemaps://?daddr=",
  iosAppleMap: "maps:0,0",
  universal: "https://www.google.com/maps/dir/",
};

export const NOTIFICATION_TYPE = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

export const NOTIFICATION_DATE_FORMATE = {
  API_DATE_FORMATE: "MM/DD/YYYY HH:mm",
  DISPLAY_TIME: "hh:mm A",
};

export const OAUTH_CONFIG = {
  client_id: "trusted_client",
  grant_type: "client_credentials",
};
export const PROJECT_LEVEL = [
  {
    key: "Master_Project",
    value: 1,
    label: "Master Project",
  },
  {
    key: "Sub_Project",
    value: 2,
    label: "Sub Project",
  },
];
export const PROJECT_TYPE = [
  {
    key: "New_Implementation",
    value: 1,
    label: "New Implementation",
  },
  {
    key: "New_Implementation_-_Launch",
    value: 2,
    label: "New Implementation-Launch",
  },
  {
    key: "Internal",
    value: 3,
    label: "Internal",
  },
  {
    key: "Conversions",
    value: 4,
    label: "Conversions",
  },
  {
    key: "Change_Order",
    value: 5,
    label: "Change Order",
  },
  {
    key: "Reporting",
    value: 6,
    label: "Reporting",
  },
  {
    key: "Integrations",
    value: 7,
    label: "Integrations",
  },
  {
    key: "Functional",
    value: 8,
    label: "Functional",
  },
  {
    key: "AMS",
    value: 9,
    label: "AMS",
  },
  {
    key: "Testing",
    value: 10,
    label: "Testing",
  },
];
export const BILLING_TYPE = [
  {
    key: "Fixed_Fee",
    value: 1,
    label: "Fixed Fee",
  },
  {
    key: "Time_and_Material",
    value: 2,
    label: "Time and Material",
  },
];
export const PROJECT_HEALTH = [
  {
    key: "Green",
    value: 1,
    label: "Green",
  },
  {
    key: "Amber",
    value: 2,
    label: "Amber",
  },
  {
    key: "Red",
    value: 3,
    label: "Red",
  },
];
export const PROJECT_STATUS = [
  {
    key: "Sold_/_Approved",
    value: 1,
    label: "Sold/Approved",
  },
  {
    key: "Live_-_Hypercare",
    value: 2,
    label: "Live - Hypercare",
  },
  {
    key: "In_Progress",
    value: 3,
    label: "In Progress",
  },
  {
    key: "Completed",
    value: 4,
    label: "Completed",
  },
  {
    key: "On_Hold",
    value: 5,
    label: "On Hold",
  },
];

export const REGION = [
  {
    key: "USA",
    value: 1,
    label: "USA",
  },
  {
    key: "APAC",
    value: 2,
    label: "APAC",
  },
  {
    key: "Canada",
    value: 3,
    label: "Canada",
  },
  {
    key: "EMEA",
    value: 4,
    label: "EMEA",
  },
  {
    key: "Africa",
    value: 5,
    label: "Africa",
  },
];
export const DELOITTE_ENTITY = [
  {
    key: "Deloitte_India",
    value: 1,
    label: "Deloitte India",
  },
  {
    key: "Deloitte_Australia",
    value: 2,
    label: "Deloitte Australia",
  },
  {
    key: "Deloitte_Canada",
    value: 3,
    label: "Deloitte Canada",
  },
  {
    key: "Deloitte_Germany",
    value: 4,
    label: "Deloitte Germany",
  },
  {
    key: "Deloitte_Ireland",
    value: 5,
    label: "Deloitte Ireland",
  },
  {
    key: "Deloitte_Kenya",
    value: 6,
    label: "Deloitte Kenya",
  },
  {
    key: "Deloitte_Malaysia",
    value: 7,
    label: "Deloitte Malaysia",
  },
  {
    key: "Deloitte_Netherlands",
    value: 8,
    label: "Deloitte Netherlands",
  },
  {
    key: "Deloitte_Singapore",
    value: 9,
    label: "Deloitte Singapore",
  },
  {
    key: "Deloitte_Spain",
    value: 10,
    label: "Deloitte Spain",
  },
  {
    key: "Deloitte_UK",
    value: 11,
    label: "Deloitte UK",
  },
  {
    key: "Deloitte_US",
    value: 12,
    label: "Deloitte US",
  },
  {
    key: "Deloitte_USI",
    value: 13,
    label: "Deloitte USI",
  },
];
export const TYPE = [
  {
    key: "Requirements",
    value: 1,
    label: "Requirements",
  },
  {
    key: "Design",
    value: 2,
    label: "Design",
  },
  {
    key: "Build",
    value: 3,
    label: "Build",
  },
  {
    key: "Testing",
    value: 4,
    label: "Testing",
  },
  {
    key: "Deploy",
    value: 5,
    label: "Deploy",
  },
];

export const REQUIREMENTS = [
  {
    key: "Data",
    value: 1,
    label: "Data",
  },
  {
    key: "Field Requirements",
    value: 2,
    label: "Field Requirements",
  },
  {
    key: "Technical",
    value: 3,
    label: "Technical",
  },
  {
    key: "Transport",
    value: 4,
    label: "Transport",
  }
];

export const PRIORITY = [
  {
    key: "High",
    value: 1,
    label: "High",
  },
  {
    key: "Medium",
    value: 2,
    label: "Medium",
  },
  {
    key: "Low",
    value: 3,
    label: "Low",
  },
  {
    key: "Critical",
    value: 4,
    label: "Critical",
  },
];
export const TEST_CASE_STATUS = [
  {
    key: "In_Progress",
    value: 1,
    label: "In Progress",
  },
  {
    key: "Pass",
    value: 2,
    label: "Pass",
  },
  {
    key: "Fail",
    value: 3,
    label: "Fail",
  },
  {
    key: "Retest",
    value: 4,
    label: "Re-test required",
  },
];

export const STATUS = [
  {
    key: "New",
    value: 1,
    label: "New",
  },
  {
    key: "In_Progress",
    value: 2,
    label: "In Progress",
  },
  {
    key: "Completed",
    value: 3,
    label: "Completed",
  },
  {
    key: "Closed",
    value: 4,
    label: "Closed",
  }
];

export const TEST_ID_TYPE = [
  {
    key: "Employee ID",
    value: 1,
    label: "Employee ID",
  },
  {
    key: "Contingent Worker ID",
    value: 2,
    label: "Contingent Worker ID",
  },
  {
    key: "Other",
    value: 3,
    label: "Other",
  },
];

export const UserType = [
  {
    key: "Customer",

    value: 1,

    label: "Customer",
  },

  {
    key: "Contractor",

    value: 2,

    label: "Contractor",
  },

  {
    key: "User",

    value: 3,

    label: "Deloitte User",
  },

  {
    key: "Admin",

    value: 4,

    label: "System/Admin",
  },
];
export const FLOW_OF_INTEGRATION = [
  {
    key: "Inbound",
    value: 1,
    label: "Inbound",
  },
  {
    key: "Outbound",
    value: 2,
    label: "Outbound",
  },
  {
    key: "Bidirectional",
    value: 3,
    label: "Bidirectional",
  },
];
export const INTEGRATION_TYPE = [
  {
    key: "Packaged_Connector",
    value: 1,
    label: "Packaged Connector",
  },
  {
    key: "GPC",
    value: 2,
    label: "GPC",
  },
  {
    key: "EIB",
    value: 3,
    label: "EIB",
  },
  {
    key: "Core_Connector",
    value: 4,
    label: "Core Connector",
  },
  {
    key: "Document_Transformation",
    value: 5,
    label: "Document Transformation",
  },
  {
    key: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    key: "API",
    value: 7,
    label: "API",
  },
  {
    key: "Raas",
    value: 8,
    label: "RaaS",
  },
  {
    key: "SSO",
    value: 9,
    label: "SSO",
  },
  {
    key: "Extend",
    value: 10,
    label: "Extend",
  },
  {
    key: "WECI",
    value: 11,
    label: "WECI",
  },
  {
    key: "PECI",
    value: 12,
    label: "PECI",
  },
  {
    key: "PICOF",
    value: 13,
    label: "PICOF",
  },
  {
    key: "WECI_+_Transformation",
    value: 14,
    label: "WECI + Transformation",
  },
  {
    key: "PICOF_+_Transformation",
    value: 15,
    label: "PICOF + Transformation",
  },
  {
    key: "PECI_+_Transformation",
    value: 16,
    label: "PECI + Transformation",
  },
  {
    key: "Core_Connector_+_Transformation",
    value: 17,
    label: "Core Connector + Transformation",
  },
  {
    key: "BIRT",
    value: 18,
    label: "BIRT",
  },
  {
    value: 19,
    label: "Other",
  },
];

export const REQUIREMENT_TEMPLATE = [
  {
    key: "Absence_Management",
    value: 1,
    label: "Absence Management",
    children: [
      {
        key: "Time_Off",
        value: 2,
        label: "Time Off",
        children: [
          {
            key: "Leave_of_Absense",
            value: 3,
            label: "Leave Of Absense",
            children: [
              {
                key: "Accural",
                value: 4,
                label: "Accural",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "Document Transformation",
    value: 5,
    label: "Document Transformation",
  },
  {
    key: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    key: "API",
    value: 7,
    label: "API",
  },
  {
    key: "Raas",
    value: 8,
    label: "RaaS",
  },
  {
    key: "SSO",
    value: 9,
    label: "SSO",
  },
  {
    key: "Extend",
    value: 10,
    label: "Extend",
  },
  {
    key: "WECI",
    value: 11,
    label: "WECI",
  },
  {
    key: "PECI",
    value: 12,
    label: "PECI",
  },
  {
    key: "PICOF",
    value: 13,
    label: "PICOF",
  },
  {
    key: "WECI_+_Transformation",
    value: 14,
    label: "WECI + Transformation",
  },
  {
    key: "PICOF_+_Transformation",
    value: 15,
    label: "PICOF + Transformation",
  },
  {
    key: "PECI_+_Transformation",
    value: 16,
    label: "PECI + Transformation",
  },
  {
    key: "Core_Connector_+_Transformation",
    value: 17,
    label: "Core Connector + Transformation",
  },
  {
    key: "Other",
    value: 18,
    label: "Other",
  },
];

export const TASK_TYPE_MAPPING = [
  {
    key: "Integrations",
    value: 1,
    label: "Integrations",
  },
  {
    key: "Functional_Configuration",
    value: 2,
    label: "Functional Configuration",
  },
];
export const SUB_TYPE_MAPPING = [
  {
    key: "AMS",
    value: 1,
    label: "AMS",
  },
  {
    key: "Implementation",
    value: 2,
    label: "Implementation",
  },
];

export const TASK_STATUS = [
  {
    key: "In_Progress",
    value: 1,
    label: "In Progress",
  },
  {
    key: "Cancelled",
    value: 2,
    label: "Cancelled",
  },
  {
    key: "Completed",
    value: 3,
    label: "Completed",
  },
  {
    key: "Out_of_Scope",
    value: 4,
    label: "Out of Scope",
  },
  {
    key: "On_Hold",
    value: 5,
    label: "On Hold",
  },
];
export const PHASE = [
  {
    key: "Requirements",
    value: 1,
    label: "Requirements",
  },
  {
    key: "Design",
    value: 2,
    label: "Design",
  },
  {
    key: "Build",
    value: 3,
    label: "Build",
  },
  {
    key: "Test",
    value: 4,
    label: "Test",
  },
  {
    key: "Deploy",
    value: 5,
    label: "Deploy",
  },
];
export const TASK_PRIORITY = [
  {
    key: "High",
    value: 1,
    label: "High",
  },
  {
    key: "Medium",
    value: 2,
    label: "Medium",
  },
  {
    key: "Low",
    value: 3,
    label: "Low",
  },
  {
    key: "Critical",
    value: 4,
    label: "Critical",
  },
  {
    key: "Normal",
    value: 5,
    label: "Normal",
  },
];
export const COMPLEXITY = [
  {
    key: "Low",
    value: 1,
    label: "Low",
  },
  {
    key: "Medium",
    value: 2,
    label: "Medium",
  },
  {
    key: "High",
    value: 3,
    label: "High",
  },
];

export const TECHNICAL = [
  {
    key: "Yes",
    value: 1,
    label: "Yes",
  },
  {
    key: "No",
    value: 2,
    label: "No",
  },
  {
    key: "NA",
    value: 3,
    label: "NA",
  },
];
export const Mode = [
  {
    key: "Changes_Only_-_Top_of_Stack",
    value: 1,
    label: "Changes Only- Top Of Stack",
  },
  {
    key: "Changes_Only_-_Full_transactions",
    value: 2,
    label: "Changes Only- Full Transactions",
  },
  {
    key: "Full_File",
    value: 3,
    label: "Full File",
  },
  {
    key: "NA",
    value: 4,
    label: "NA",
  },
];
export const FORCHANGEFILE = [
  {
    key: "Entered_as_of_Date",
    value: 1,
    label: "Entered As Of Date",
  },
  {
    key: "Effective_as_of_Date",
    value: 2,
    label: "Effective As Of Date",
  },
  {
    key: "NA",
    value: 3,
    label: "NA",
  },
];

export const DATAPROCESSED = [
  {
    key: "Yes",
    value: 1,
    label: "Yes",
  },
  {
    key: "No",
    value: 2,
    label: "No",
  },
  {
    key: "NA",
    value: 3,
    label: "NA",
  },
];
export const HIRE_RECORDS = [
  {
    key: "Sent_once",
    value: 1,
    label: "Sent Once",
  },
  {
    key: "Sent_always",
    value: 2,
    label: "Sent Always",
  },
  {
    key: "Excluded",
    value: 3,
    label: "Excluded",
  },
  {
    key: "Included_for_a_time_range",
    value: 4,
    label: "Included For A Time Range",
  },
  {
    key: "NA",
    value: 5,
    label: "NA",
  },
];
export const DELIVERY_TRANSPORT = [
  {
    key: "AS3",
    value: 1,
    label: "AS3",
  },
  {
    key: "AS2",
    value: 2,
    label: "AS2",
  },
  {
    key: "Email",
    value: 3,
    label: "Email",
  },
  {
    key: "FTP",
    value: 4,
    label: "FTP",
  },
  {
    key: "FTP/SSL",
    value: 5,
    label: "FTP/SSL",
  },
  {
    key: "Google_Cloud_Storage",
    value: 6,
    label: "Google Cloud Storage",
  },
  {
    key: "HTTP_/_SSL",
    value: 7,
    label: "HTTP/SSL",
  },
  {
    key: "SFTP",
    value: 8,
    label: "SFTP",
  },
];

export const TASK_ROLE = [
  {
    key: "Project_Manager",
    value: 1,
    label: "Project Manager",
  },
  {
    key: "Integration_Architect",
    value: 2,
    label: "Integration Architect",
  },
  {
    key: "Integration_Lead",
    value: 3,
    label: "Integration Lead",
  },
  {
    key: "Integration_Owner",
    value: 4,
    label: "Integration Owner",
  },
  {
    key: "Integration_Developer",
    value: 5,
    label: "Integration Developer",
  },
  {
    key: "Integration_Tester",
    value: 6,
    label: "Integration Tester",
  },
];
export const FUNCTIONAL_AREA = [
  {
    key: "HCM",
    value: 1,
    label: "HCM",
  },
  {
    key: "Benefits",
    value: 2,
    label: "Benefits",
  },
  {
    key: "Payroll",
    value: 3,
    label: "Payroll",
  },
  {
    key: "Recruiting",
    value: 4,
    label: "Recruiting",
  },
  {
    key: "SSO",
    value: 5,
    label: "SSO",
  },
  {
    key: "Absence_Management",
    value: 6,
    label: "Absence Management",
  },
  {
    key: "Finance",
    value: 7,
    label: "Finance",
  },
];

export const TEMPLATE_DROPDOWNS = [
  {
    key: "Absence_Management",
    value: "Absence Management",
    subFields: [
      {
        key: "Time_Off",
        value: "Time Off",
      },
      {
        key: "Leave_of_Absence",
        value: "Leave Of Absence",
      },
      {
        key: "Accrual",
        value: "Accrual",
      },
    ],
  },
  {
    key: "Benefits",
    value: "Benefits",
    subFields: [
      {
        key: "Benefits",
        value: "Benefits",
      },
      {
        key: "Benefit_Inbound",
        value: "Benefits Inbound",
      },
      {
        key: "Benefit_Outbound",
        value: "Benefits Outbound",
      },
      {
        key: "834_Outbound",
        value: "834 Outbound",
      },
      {
        key: "BAR_Bi_directional",
        value: "Bar Bi-Directional",
      },
      {
        key: "Cobra_NH_Outbound",
        value: "Cobra NH Outbound",
      },
      {
        key: "Cobra_Outbound",
        value: "COBRA Outbound",
      },
      {
        key: "Cobra_QE_Outbound",
        value: "Cobra QE Outbound",
      },
      {
        key: "Life_/_Disability_Outbound",
        value: "Life/Disability Outbound",
      },
    ],
  },
  {
    key: "HCM",
    value: "HCM",
    subFields: [
      {
        key: "HCM",
        value: "HCM",
      },
      {
        key: "Inactive_Employee_Update_Inbound",
        value: "Inactive Employee Update Inbound",
      },
      {
        key: "Organization_Inbound",
        value: "Organization Inbound",
      },
      {
        key: "Organizations",
        value: "Organizations",
      },
      {
        key: "Outbound_Terminations",
        value: "Outbound Terminations",
      },
      {
        key: "E_verify",
        value: "E-Verify",
      },
      {
        key: "Employee_Photos",
        value: "Employee Photos",
      },
      {
        key: "EOI_Outbound",
        value: "EOI Outbound",
      },
      {
        key: "Equifax",
        value: "Equifax",
      },
      {
        key: "Job_Postings",
        value: "Job Postings",
      },
    ],
  },
  {
    key: "Payroll",
    value: "Payroll",
    subFields: [
      {
        key: "Pay_Elections",
        value: "Pay Elections",
      },
      {
        key: "Payroll",
        value: "Payroll",
      },
      {
        key: "Payroll_Interface",
        value: "Payroll Interface",
      },
      {
        key: "Check_Print",
        value: "Check Print",
      },
      {
        key: "PI_Connector",
        value: "PI Connector",
      },
      {
        key: "PI_Inbound",
        value: "PI Inbound",
      },
      {
        key: "Positive_Pay",
        value: "Positive Pay",
      },
      {
        key: "Off_Cycle_Payment",
        value: "Off-Cycle Payment",
      },
      {
        key: "One_Time_Payments",
        value: "One Time Payments",
      },
      {
        key: "FSA_HSA_Outbound",
        value: "FSA/HSA Outbound",
      },
      {
        key: "Garnishment_Inbound",
        value: "Garnishment - Inbound",
      },
      {
        key: "Garnishment_Outbound",
        value: "Garnishment - Outbound",
      },
      {
        key: "401K_Outbound",
        value: "401k Outbound",
      },
    ],
  },
  {
    key: "Recruiting",
    value: "Recruiting",
    subFields: [
      {
        key: "Background_Check",
        value: "Background Check",
      },
      {
        key: "Job_Posting",
        value: "Job Posting",
      },
      {
        key: "Recruting_Hire_Inbound",
        value: "Recruiting - Hire Inbound",
      },
      {
        key: "Recruiting_Positions",
        value: "Recruiting Positions",
      },
    ],
  },
  {
    key: "SSO",
    value: "SSO",
    subFields: [
      {
        key: "Active_Directory",
        value: "Active Directory",
      },
      {
        key: "Authentication",
        value: "Authentication",
      },
      {
        key: "SSO",
        value: "SSO",
      },
    ],
  },
  {
    key: "Finance",
    value: "Finance",
    subFields: [
      {
        key: "Credit_Cards",
        value: "Credit Cards",
      },
      {
        key: "Currency_Rates",
        value: "Currency Rates",
      },
      {
        key: "Financials",
        value: "Financials",
      },
      {
        key: "Expenses",
        value: "Expenses",
      },
      {
        key: "GL_Inbound",
        value: "GL Inbound",
      },
      {
        key: "General_Ledger",
        value: "General Ledger",
      },
      {
        key: "Procurement",
        value: "Procurement",
      },
      {
        key: "Receivables",
        value: "Receivables",
      },
      {
        key: "Tax",
        value: "Tax",
      },
      {
        key: "Tax_Rates_Outbound",
        value: "Tax Rates Outbound",
      },
      {
        key: "Other",
        value: "Other",
      }
    ],
  },
];

export const CONVERTED_KEYS: any = {
  Absence_Management: "Absence Management",
  Time_Off: "Time Off",
  Leave_of_Absence: "Leave Of Absence",
  Accrual: "Accrual",
  Benefits: "Benefits",
  Benefit_Inbound: "Benefits Inbound",
  Benefit_Outbound: "Benefits Outbound",
  "834_Outbound": "834 Outbound",
  BAR_Bi_directional: "Bar Bi-Directional",
  Cobra_NH_Outbound: "Cobra NH Outbound",
  Cobra_Outbound: "COBRA Outbound",
  Cobra_QE_Outbound: "Cobra QE Outbound",
  "Life_/_Disability_Outbound": "Life/Disability Outbound",
  HCM: "HCM",
  Inactive_Employee_Update_Inbound: "Inactive Employee Update Inbound",
  Organization_Inbound: "Organization Inbound",
  Organizations: "Organizations",
  Outbound_Terminations: "Outbound Terminations",
  E_verify: "E-Verify",
  Employee_Photos: "Employee Photos",
  EOI_Outbound: "EOI Outbound",
  Equifax: "Equifax",
  Job_Postings: "Job Postings",
  Job_Posting:"Job Posting",
  Payroll: "Payroll",
  Pay_Elections: "Pay Elections",
  Payroll_Interface: "Payroll Interface",
  Check_Print: "Check Print",
  PI_Connector: "PI Connector",
  PI_Inbound: "PI Inbound",
  Positive_Pay: "Positive Pay",
  Off_Cycle_Payment: "Off-Cycle Payment",
  One_Time_Payments: "One Time Payments",
  FSA_HSA_Outbound: "FSA/HSA Outbound",
  Garnishment_Inbound: "Garnishment - Inbound",
  Garnishment_Outbound: "Garnishment - Outbound",
  "401K_Outbound": "401k Outbound",
  Recruiting: "Recruiting",
  Background_Check: "Background Check",
  Recruting_Hire_Inbound: "Recruiting - Hire Inbound",
  Recruiting_Positions: "Recruiting Positions",
  SSO: "SSO",
  Active_Directory: "Active Directory",
  Authentication: "Authentication",
  Credit_Cards: "Credit Cards",
  Currency_Rates: "Currency Rates",
  Financials: "Financials",
  Expenses: "Expenses",
  GL_Inbound: "GL Inbound",
  General_Ledger: "General Ledger",
  Procurement: "Procurement",
  Receivables: "Receivables",
  Tax: "Tax",
  Tax_Rates_Outbound: "Tax Rates Outbound",
  Other: "Other"
};
export const TASK_RESOURCES_ROLE = [
  {
    key: "Project_Manager",
    value: 1,
    label: "Project Manager",
  },
  {
    key: "Integration_Architect",
    value: 2,
    label: "Integration Architect",
  },
  {
    key: "Integration_Lead",
    value: 3,
    label: "Integration Lead",
  },
  {
    key: "Integration_Owner",
    value: 4,
    label: "Integration Owner",
  },
  {
    key: "Integration_Developer",
    value: 5,
    label: "Integration Developer",
  },
  {
    key: "Integration_Tester",
    value: 6,
    label: "Integration Tester",
  },
];
export const TASK_FILE_CATEGORY = [
  {
    key: "Vendor Requirements",
    value: 1,
    label: "Vendor Requirements",
  },
  {
    key: "Design",
    value: 2,
    label: "Design",
  },
  {
    key: "Sample File",
    value: 3,
    label: "Sample File",
  },
  {
    key: "Client Documentation",
    value: 4,
    label: "Client Documentation",
  },
  {
    key: "Timeline",
    value: 5,
    label: "Timeline",
  },
  {
    key: "Unit Testing",
    value: 6,
    label: "Unit Testing",
  },
  {
    key: "SIT-Results",
    value: 7,
    label: "SIT-Results",
  },
  {
    key: "SIT-Approval",
    value: 8,
    label: "SIT-Approval",
  },
  {
    key: "QA-Results",
    value: 9,
    label: "QA-Results",
  },
  {
    key: "QA-Approval",
    value: 10,
    label: "QA-Approval",
  },
  {
    key: "UAT-Results",
    value: 11,
    label: "UAT-Results",
  },
  {
    key: "UAT-Approval",
    value: 12,
    label: "UAT-Approval",
  },
  {
    key: "Contract",
    value: 13,
    label: "Contract",
  },
  {
    key: "Other",
    value: 14,
    label: "Other",
  },
];

export const TECHNICAL_TASK_FILE_CATEGORY = [
  {
    key: "CLAR",
    value: 1,
    label: "CLAR",
  },
  {
    key: "XSLT",
    value: 2,
    label: "XSLT",
  },
  {
    key: "XML",
    value: 3,
    label: "XML",
  },
  {
    key: "XSD",
    value: 4,
    label: "XSD",
  },
  {
    key: "ZIP",
    value: 5,
    label: "ZIP",
  },
  {
    key: "JSON",
    value: 6,
    label: "JSON",
  },
  {
    key: "RPTDESIGN",
    value: 7,
    label: "RPTDESIGN",
  },
  {
    key: "OTHER",
    value: 8,
    label: "OTHER",
  }
];

export const TASK_FILE_VIEW_BY = [
  {
    key: "All",
    value: 1,
    label: "All",
  },
  {
    key: "Deloitte Internal Only",
    value: 2,
    label: "Deloitte Internal Only",
  },
  {
    key: "Deloitte Internal Restricted",
    value: 3,
    label: "Deloitte Internal Restricted",
  },
];

export const FILE_BASE_URL = "http://localhost:3000";