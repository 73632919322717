import React, { useEffect, useMemo } from "react";
import Paper from "@mui/material/Paper";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import columns from "./columnsDef";
import { TableCellStyle } from "./style";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import FieldRequirementList from "../FieldRequirementList";

type MappingDataType = {
  field_order: number;
  Source_Value: string;
  Target_Value: string;
  Notes: string;
} | null;


const FieldRequirementPopUp = ({
  fielwRquirementList,
  mappingRow,
  handleChange,
  onAddMappingRow,
  onMappingDeleteRow,
  activeFieldOrder,
  setActiveFieldOrder
}: any) => {
  const [rowID, setRowID] = React.useState("");
  const [editStop, setEditStop] = React.useState(false);
  const [error,setError] = React.useState<{rowID:number,isSourceValueValid:boolean,isTargetValueValid:boolean}[]>([]);
  const defaultColumns: any = useMemo(() => {
    return columns();
  }, []);

  //adds new error if field is invalid and removes error if field is valid
  function updateErrors(mappingData:MappingDataType):boolean
  {
    let isErrorPresent=true;
    if(!mappingData)
        return false;
    setError((oldErrors) => {
      const existingErrorIndex = oldErrors.findIndex(
        (error) => error.rowID === mappingData.field_order
      );
    
      if (existingErrorIndex !== -1) {
        // Update existing error
        const updatedErrors = [...oldErrors];
        updatedErrors[existingErrorIndex] = {
          ...updatedErrors[existingErrorIndex],
          rowID: mappingData.field_order,
          isSourceValueValid: mappingData.Source_Value.length>=1 && mappingData.Source_Value.length<=100,
          isTargetValueValid: mappingData.Target_Value.length>=1 && mappingData.Target_Value.length<=100,
        };
        return updatedErrors;
      } else {
        // Add new error
        return [
          ...oldErrors,
          { rowID: mappingData.field_order, isSourceValueValid: mappingData.Source_Value.length>=1 && mappingData.Source_Value.length<=100, isTargetValueValid: mappingData.Target_Value.length>=1 && mappingData.Target_Value.length<=100 },
        ];
      }
    })
    // remove error if everything is valid
    if(mappingData.Source_Value.length>=1 && mappingData.Source_Value.length<=100 && mappingData.Target_Value.length>=1 && mappingData.Target_Value.length<=100)
    {
      isErrorPresent=false;
      setError((oldErrors) => oldErrors.filter((error) => error.rowID !== mappingData.field_order));
    }
    return isErrorPresent;
  }

  const OnClickEdit = (RowVal: any, editShow: any) => {
    setEditStop(editShow);
    setRowID(RowVal);
  };
  let listNo = fielwRquirementList?.filter((arrayList: any) => {
    return (
      arrayList.type === mappingRow?.type &&
      arrayList.field_order === mappingRow?.field_order
    );
  });

  const onAddClick = () => {
    let currMapLen=listNo[0]?.field_attributes?.Mapping?.length;
    let lastMappedData:{field_order:number,Source_Value:string,Target_Value:string,Notes:string}|null=null;
    if(currMapLen>0)
      lastMappedData = listNo[0]?.field_attributes?.Mapping[currMapLen-1]||null;
    
    let isErrorPresent = updateErrors(lastMappedData);
    if(isErrorPresent) return;

    setEditStop(true);
    setRowID(listNo[0]?.field_attributes?.Mapping?.length+1);
    onAddMappingRow(
        listNo[0],
        listNo[0]?.field_attributes?.Mapping?.length
    )
  }

  useEffect(() => {
    const initialError = mappingRow.field_attributes.Mapping.map((data,index) => {
      return {
        rowID: index+1,
        isSourceValueValid: data.Source_Value.length >= 1 && data.Source_Value.length <= 100,
        isTargetValueValid: data.Target_Value.length >= 1 && data.Target_Value.length <= 100,
      };
    });

    setError(initialError);
  }, []);

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Button
          variant="contained"
          onClick={onAddClick}>
          ADD
        </Button>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {defaultColumns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listNo[0]?.field_attributes?.Mapping?.length === 0 && (
              <TableRow
                hover
                className="text-center"
                sx={{
                  background: "#f6f6f6",
                }}
              >
                <TableCell
                  align="center"
                  colSpan={12}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  No Rows
                </TableCell>
              </TableRow>
            )}
            {fielwRquirementList?.map((row: any, index: any) => {
              return row.type === mappingRow?.type &&
                row.field_order === mappingRow?.field_order &&
                row?.field_attributes?.Mapping?.length > 0
                ? row?.field_attributes?.Mapping?.map((mappingData: any, mIndex: number) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={mappingData?.field_order}
                        sx={{ verticalAlign: (activeFieldOrder !== mappingData?.field_order) ? 'center' : 'top' }}
                      >
                        {defaultColumns.map((column: any) => {
                          const valueData = mappingData?.[column?.id];
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                sx={TableCellStyle}
                                align={column.align}
                                style={{verticalAlign:"top"}}
                              >
                                {column?.id === "Action" ? (
                                  <div className="d-inline-flex">
                                    {editStop && mIndex == rowID - 1 ? (
                                      <IconButton
                                      sx={{padding:"8px 0px"}}
                                        onClick={() => {
                                          let isErrorPresent=updateErrors(mappingData)
                                          
                                          if(isErrorPresent)
                                          {
                                            OnClickEdit(
                                              mappingData?.field_order,
                                              true
                                            );
                                          }
                                          else
                                          {
                                            setActiveFieldOrder(null);
                                            OnClickEdit(
                                              mappingData?.field_order,
                                              false
                                            );
                                          }                                          
                                        }}
                                      >
                                        <CheckOutlinedIcon/>
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => {
                                          if(activeFieldOrder!==mappingData?.field_order && activeFieldOrder!==null)
                                            return;
                                          setActiveFieldOrder(mappingData?.field_order);
                                          OnClickEdit(
                                            mappingData?.field_order,
                                            true
                                          );
                                        }}
                                        className="p-0"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    )}
                                    <IconButton
                                      onClick={() =>{
                                        // remove error if present in the curr field
                                        if(activeFieldOrder!==mappingData?.field_order && activeFieldOrder!==null)
                                          return;
                                        setActiveFieldOrder(null);
                                        setError((oldErrors) => oldErrors.filter((error) => error.rowID !== mappingData.field_order));
                                        onMappingDeleteRow(
                                          row,
                                          mappingData?.field_order
                                        )
                                        }
                                      }
                                      className="p-0"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <>
                                    {editStop &&
                                    rowID === mIndex + 1  &&
                                    column?.id !== "field_order" ? (
                                      <input
                                        type="text"
                                        id={column?.id}
                                        name={column?.id}
                                        onChange={
                                          handleChange(
                                            column?.id,
                                            "",
                                            "RequirementFields",
                                            "",
                                            "",
                                            mappingRow.id,
                                            true,
                                            "field_attributes",
                                            "",
                                            "",
                                            "",
                                            "",
                                            "",
                                            true,
                                            "Mapping",
                                            mappingData?.field_order,
                                            "field_order"
                                          )}
                                        value={valueData}
                                        placeholder=""
                                        style={{
                                          width: "80px",
                                          height: "45px",
                                          textAlign: "center",
                                          border: "1px solid blue",
                                          borderColor: ((column?.id==="Source_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isSourceValueValid))||(column?.id==="Target_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isTargetValueValid)))?"#e65050": "#86b7fe",
                                          outline: 0,
                                          boxShadow: ((column?.id==="Source_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isSourceValueValid))||(column?.id==="Target_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isTargetValueValid)))?"0 0 0 0.25rem rgb(230 80 80 / 25%)":"0 0 0 0.25rem rgb(13 110 253 / 25%)",
                                        }}
                                      />
                                    ) : (
                                      <div className="text-capitalize">
                                        {valueData}
                                      </div>
                                    )}
                                    {((column?.id==="Source_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isSourceValueValid))||(column?.id==="Target_Value" && error.length>0 &&  error.some(error => error.rowID === mIndex + 1 && !error.isTargetValueValid))) && (<div className="pt-1 text-danger " >Required*</div>)}

                                  </>
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default FieldRequirementPopUp;
